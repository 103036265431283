import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contact.module.css';
import aboutFoto from "../../assets/images/Foto Quelle Thal.JPG";
import aboutFoto2 from "../../assets/images/20221115_161310.jpg";

const Contact = () => (
<div className={styles.Contact}>
  <div className={styles.itemText}>
      <h3>Adresse</h3>
      <p>Hauptstrasse 42<br />
          9030 Abtwil SG</p>
  </div>
    <div className={styles.itemMap}>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1349.7376975695975!2d9.319292675309624!3d47.422172867081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ae1006c79e907%3A0xfb3089fb1c49753e!2sGesundheitspraxis%20Xundheitspraxis%20Baumann!5e0!3m2!1sde!2sch!4v1731763725392!5m2!1sde!2sch"
            frameborder="0" allowFullScreen="" loading="lazy" height="100%" width="100%"
            referrerPolicy="no-referrer-when-downgrade">
    </iframe>
</div>
<div className={styles.itemText}>
    <h3>Telefon</h3>
    <p>Mobil 079 412 49 29 <br/>
    </p>
</div>
</div>
)
;

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
/*

  <div className={styles.itemFoto}>
      <img src={aboutFoto} alt="Image here" height="100%" width="100%"/>
  </div>
  <div className={styles.itemText}>
      <h3>Mail</h3>
      <p>daniel@baumann.ch</p>
  </div>
    <div className={styles.itemFoto}>
        <img src={aboutFoto2} alt="Image here" height="100%" width="100%"/>
    </div>

 */
