// https://dev.to/sampurna/creating-responsive-navbar-using-react-bootstrap-5ajg
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Navigationbar.module.css';
import {Container, Nav, Navbar, NavDropdown, NavLink} from "react-bootstrap";
import { Link } from "react-dom";
import simpleLogo from "../../assets/images/Logo.png";
import bgImage from "../../assets/images/bg_header.jpg";

const Navigationbar = () => {
    return (
        <Navbar collapseOnSelect expand="sm"   variant="light"  className={styles.Navigationbar} >
            <Container fluid className={styles.navbarTabs} >
                <Navbar.Brand href="/">
                    <img src={simpleLogo} alt="Image here" height="100"/>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" >
                    <Nav >
                        <Nav.Link  href="/#/"><h4 className={styles.navLink}> Daniel Baumann</h4></Nav.Link>
                        <Nav.Link href="/#/about"><h4 className={styles.navLink}> GMT</h4></Nav.Link>
                        <Nav.Link href="/#/contact"><h4 className={styles.navLink}>Kontakt</h4></Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>

        </Navbar>
    );
}


Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navigationbar;
/*

                        <Nav.Link href="praxis"><h4 className={styles.navLink}>Praxis </h4></Nav.Link>
 */
