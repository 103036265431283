import React from 'react';
import PropTypes from 'prop-types';
import styles from "./Praxis.module.css";
import praxisFoto from "../../assets/images/Foto Quelle Thal.JPG";
// import praxisFoto2 from "../../assets/images/20230425_113642.jpg";
import praxisFoto3 from "../../assets/images/20221115_161310.jpg";

const Praxis = () => (
    <div className={styles.Praxis}>

        <div className={styles.itemFoto}>
            <img src={praxisFoto} alt="Image here"/>
        </div>
        {/*<div className={styles.itemFoto}>*/}
        {/*    <img src={praxisFoto2} alt="Image here" />*/}
        {/*</div>*/}
        <div className={styles.itemFoto}>
            <img src={praxisFoto3} alt="Image here" />
        </div>

    </div>
);

Praxis.propTypes = {};

Praxis.defaultProps = {};

export default Praxis;
